// store.js
import { configureStore } from '@reduxjs/toolkit';

const initialState = {
  dwallet: '',
  dconnected: false,
  daddress: '',
  mintopenwallet: false,
  dselectedfeatures: [],
  mylands: [],
  myrealms: [],
  signsession: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_DWALLET':
      return {
        ...state,
        dwallet: action.payload,
      };
    case 'SET_DCONNECTED':
      return {
        ...state,
        dconnected: action.payload,
      };
    case 'SET_DADDRESS':
      return {
        ...state,
        daddress: action.payload,
      };
    case 'SET_MINTOPENWALLET':
      return {
        ...state,
        mintopenwallet: action.payload,
      };
    case 'SET_DSELECTEDFEATURES':
      return {
        ...state,
        dselectedfeatures: action.payload,
      };
    case 'SET_MYLANDS':
      return {
        ...state,
        mylands: action.payload,
      };
      case 'SET_MYREALMS':
        return {
          ...state,
          myrealms: action.payload,
        };
    case 'SET_SIGNSESSION':
      return {
        ...state,
        signsession: action.payload,
      };

    default:
      return state;
  }
}

const store = configureStore({
  reducer,
});

export default store;
