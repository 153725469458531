import React, { useEffect } from 'react';
import { Card, Flex} from 'antd';
import Icon from '@ant-design/icons'

function MyLand({ showChildrenDrawer }) {

  const landSvg=() => (
    <svg width="2em" height="2em" viewBox="64 64 896 896" ><path fill="#333333" d="M900.992 384.597333c9.984 69.845333-129.877333 416.298667-227.285333 445.994667-97.322667 29.696-580.394667-141.482667-590.549334-219.178667-22.058667-168.789333 257.493333-344.746667 380.928-363.434666 112.298667-17.024 426.922667 66.730667 436.906667 136.618666zM375.68 364.288c-37.973333 42.453333 129.066667 60.8 148.992 58.026667 20.053333-2.858667 86.186667-80.64 72.362667-89.130667-10.282667-6.314667-83.2-22.954667-119.594667-30.976a50.901333 50.901333 0 0 0-40.234667 8.192 357.205333 357.205333 0 0 0-61.525333 53.888z m223.530667 40.618667c-26.666667 43.477333 126.037333 80.554667 142.506666 82.090666 16.768 1.536 45.653333-26.581333 57.685334-37.845333 12.032-11.349333 16.213333-28.288 6.229333-44.245333-8.234667-13.141333-106.88-47.786667-140.885333-59.392a21.504 21.504 0 0 0-21.76 4.949333 260.906667 260.906667 0 0 0-43.776 54.442667z m-22.186667 282.453333c14.08 2.005333 28.245333-3.242667 38.485333-14.378667 25.728-27.989333 74.752-83.626667 92.8-119.68a20.224 20.224 0 0 0-3.669333-23.168c-31.658667-30.378667-121.514667-48.725333-158.037333-55.04a32.469333 32.469333 0 0 0-28.117334 8.96c-54.314667 51.882667-94.293333 88.490667-106.24 127.573334-3.84 12.672 2.432 26.410667 13.653334 30.421333 35.242667 12.544 114.901333 40.021333 151.168 45.312z m-382.72-178.816c-82.005333 81.962667 76.288 104.106667 114.858667 101.76 30.549333-1.792 129.408-115.925333 136.874666-138.581333 6.570667-19.712-102.912-48.938667-130.901333-56.064a17.621333 17.621333 0 0 0-13.226667 1.834666c-13.226667 7.594667-46.762667 30.165333-107.648 91.008z" /></svg>
  );
  const LandIcon = (props) => <Icon component={landSvg} {...props} />;
  const cardStyle = {
    width: '100%',
    marginBottom:'5px'
  };
  const imgStyle = {
    display: 'block',
    alignSelf:'center',
    marginLeft:26,
  };
  
  useEffect(() => {
   
  }, []);
  return (
    <div>
        <Card
          hoverable
          style={cardStyle}
          onClick={showChildrenDrawer}
          styles={{
            body: {
              padding: 0,
              overflow: 'hidden',
            },
          }}
        >
          <Flex>
            <LandIcon style={imgStyle}/>
            <Flex
              vertical
              style={{
                padding: 26,
                fontSize:16,
                fontWeight:400
              }}
            >
                MyLands 
            </Flex>
          </Flex>
        </Card>
        
      </div>  
  );
}

export default MyLand;
