import React, { useRef, useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { Rnd } from 'react-rnd';
import axios from 'axios';
import { message } from 'antd';
import TopBar from './components/TopBar';
import Dmint from './components/Dmint.js';
import Setupland from './components/Setupland.js';
import CustomMarker from './components/CustomMarker.js';
import './Map.css';
import { geourl } from './networkConfig.js';
// import  soldsvg from './assets/soldgreen.svg';
// import mountian from './data/mountian.json'
const alllands = [...Array(10)]
const alllabelsid = []
const citycolors = [
  {
      border: '#113658',
      background: '#63bcd1',
      mbackground: '#63bcd1',
      layerid:'',
      city: ''
  },
  {
      border: '#f7d8c6',
      mbackground: '#ef7a34',
      background: '#eab589',
      layerid:'quarks',
      city: 'Quark City'
  },
  {
      border: '#cee3e6',
      mbackground: '#227638',
      background: '#9ed764',
      layerid:'neutrons',
      city: 'Neutron City'
  },
  {
      border: '#cee3e6',
      mbackground: '#4a8188',
      background: '#77b6bd',
      layerid:'electrons',
      city: 'Electron City'
  },
  {
      border: '#113658',
      background: '#63bcd1',
      mbackground: '#63bcd1',
      layerid:'',
      city: ''
  },
  {
      border: '#eff3ff',
      mbackground: '#1f84e0',
      background: '#bdd2ff',
      layerid:'photons',
      city: 'Photon City'
  },
  {
      border: '#d7c9e3',
      mbackground: '#956db6',
      background: '#baa4d2',
      layerid:'infinitys',
      city: 'Infinity City'
  },
  {
      border: '#113658',
      background: '#63bcd1',
      mbackground: '#63bcd1',
      layerid:'',
      city: ''
  },
  {
      border: '#113658',
      background: '#63bcd1',
      mbackground: '#63bcd1',
      layerid:'islands',
      city: 'Island'
  },
  {
      border: '#feb24c',
      mbackground: '#144bb2',
      background: '#3182bd',
      layerid:'sophons',
      city: 'Sophon Continent'
  }
];

mapboxgl.accessToken ='pk.eyJ1IjoiZmVsb3VtIiwiYSI6ImNsc2tnaTBrODAyeHMycW44bmZjZWtraXAifQ.TRWSNbHc3NiFdNut6R0Tog';
  // 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';
  //"pk.eyJ1IjoiY29waWxvdCIsImEiOiJja3R6cGZ0MmYwMnZtMnZvNnF4b2V6a2ZnIn0.7XoQ8f9g9Z5c0yZlZ4Z6qg"
const Map = ({ enableResizing = { top: false, right: true, bottom: true, left: false, topRight: false, bottomRight: true, bottomLeft: false, topLeft: false } }) => {

  const [messageApi, contextHolder] = message.useMessage();
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [alllandsload,setAlllandsload]= useState(0);
  const [mylandsgeo, setMylandsgeo] = useState({type: 'FeatureCollection', features: []});
  const popupRef = useRef(new mapboxgl.Popup({ anchor: 'right', closeButton: true }));

  const [show, setShow] = useState(false);
  const [showmyset, setShowmyset] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 10, height: 10 });
  const [rowcol, setRowCol] = useState({ rows: 1, cols: 1 });
  const [minsize, setminSize] = useState({ width: 10, height: 10 });
  const [maxsize, setmaxSize] = useState({ maxWidth: 100, maxHeight: 100 });
  const [currSelectedFeatures,setcurrSelectedFeatures]=useState([]);
  const [isIrregularLand, setIsIrregularLand] = useState(false);
  const [renderisland, setRenderisland] = useState(false);
  
  const dconnected = useSelector(state => state.dconnected);
  // const dwallet = useSelector(state => state.dwallet);
  const daddress = useSelector(state => state.daddress);
  const mylands = useSelector(state => state.mylands);
  const signsession = useSelector(state => state.signsession);
  const dispatch = useDispatch();
  const dmintRef = useRef(null);
  const setupRef = useRef(null);

  const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const urllid = queryParams.get('l')

  const handleDmintShowModal = () => {
    dmintRef.current.showModal();
  };
  const handleSetupShowModal = () => {
    setupRef.current.showModal();
  };
  const flyerror = () => {
    messageApi.open({
      type: 'error',
      content: 'Unable to find the corresponding coordinates.',
    });
  };
  const verifyerror = () => {
    messageApi.open({
      type: 'error',
      content: 'Verify error.',
    });
  };
  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
       //style: 'mapbox://styles/mapbox/streets-v11',
      // style:'mapbox://styles/feloum/clsu0cu3x00g701qqfwvo40ca',
      style:'mapbox://styles/feloum/clt9qk98s00du01qp92u3cc0y',
      center: [-80.5,-28.8],
      zoom: 1,
      minZoom: 0,
      maxZoom:12,
      projection: 'globe',
    });

    map.on('load', () => {
      if(!urllid){
        map.flyTo({
          center: [-121,-32],
          zoom: 6,
          speed: 0.2,
          curve: 1,
          duration: 12000,
          essential: true
        });
      }
      const mapboxglCtrlTopRight = document.querySelector('.mapboxgl-ctrl-top-right');
        if (mapboxglCtrlTopRight) {
          const newDiv = document.createElement('div');
          newDiv.className = 'mapboxgl-ctrl mapboxgl-ctrl-group'; 
          const PitchButton = document.createElement('button');
          PitchButton.className = 'pitch-button mapboxgl-ctrl-fullscreen';
          const pitchSvg1='<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="29px" height="29px" viewBox="0 0 29 29" style="enable-background:new 0 0 29 29;" xml:space="preserve"><style type="text/css">	.st1{fill:#000000;}</style><path class="st1" d="M24,21.3H5L8.5,7.7H22C22,7.7,24,21.3,24,21.3z M6,20.5h17.1l-1.7-12H9L6,20.5z"/><path class="st1" d="M7.8,10.4h14.4v0.7H7.8V10.4z M6.9,13.8h16v0.7h-16V13.8z M6.1,17.5h17.3v0.7H6.1V17.5z"/><path class="st1" d="M8,20.8l3.3-13L11.7,8L8.4,21L8,20.8z M11.3,21l2.1-13.2l0.4,0.1l-2.1,13.2L11.3,21z M14.4,21.1l1.1-13.2l0.4,0l-1.1,13.2L14.4,21.1z M17.5,21.2l0.2-13.4l0.5,0L18,21.2L17.5,21.2z M19.7,7.8l0.4,0L21,20.9l-0.4,0L19.7,7.8z"/></svg>';
          const pitchSvg2='<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="29px" height="29px" viewBox="0 0 29 29" style="enable-background:new 0 0 29 29;" xml:space="preserve"><style type="text/css">	.st1{fill:#000000;}</style><path class="st1" d="M24,21.3H5V7.7h19V21.3z M5.8,20.6h17.3V8.4H5.8V20.6z"/><path class="st1" d="M5.1,10.7h18.8v0.7H5.1V10.7z M5.2,17.8h18.8v0.7H5.2V17.8z M5.2,14.2h18.8v0.7H5.2V14.2z"/><path class="st1" d="M8.6,7.8h0.6v13.3H8.6V7.8z M12.6,7.9h0.6v13.3h-0.6V7.9z M16.5,7.9h0.6v13.3h-0.6V7.9z M19.8,8h0.6v13.3h-0.6 V8z"/></svg>';
          PitchButton.innerHTML = pitchSvg1;
          let togglePitch=false;
          let intervalId=null;
          let currentPitch = 0;
          PitchButton.addEventListener('click', function() {
            togglePitch=!togglePitch;
            if (togglePitch) {
              PitchButton.innerHTML = pitchSvg2;
            } else {
              PitchButton.innerHTML = pitchSvg1;
            }
            clearInterval(intervalId);
            if(togglePitch){
              intervalId = setInterval(() => {
                currentPitch += 1;
                      map.setPitch(currentPitch);
                      if (currentPitch >= 70) {
                          clearInterval(intervalId);
                      }
                  }, 100);
            }else{
              intervalId = setInterval(() => {
                currentPitch -= 1;
                      map.setPitch(currentPitch);
                      if (currentPitch === 0) {
                          clearInterval(intervalId);
                      }
                  }, 100);
            }
          });
          newDiv.appendChild(PitchButton);
          mapboxglCtrlTopRight.appendChild(newDiv);
        }
 //开始渲染网格=====================
let aloadi = 0;
function updateAloadi() {
  aloadi++;
  setAlllandsload(aloadi);
}
for (let ci = 1; ci < 10; ci++) {
  if (ci !== 4 && ci !== 7) {
    const baselayerid = citycolors[ci].layerid;
    axios.get(geourl + baselayerid)
    .then(response => {
      const data = response.data;
      alllands[ci] = data;
      renderAlands(data, ci);
        updateAloadi();
      })
    .catch(error => {
      console.error(error);
    });
  }
}

    //   const soldimage = new Image(30,30);
    //   soldimage.onload = () => {
    //   map.addImage('soldimgsource', soldimage);
    // };
    // soldimage.src = soldsvg;   
    const renderAlands=(data,citynum)=>{
      if(data){
        //==========all land=====================
        const baselayerid=citycolors[citynum].layerid
        const lbordercolor=citycolors[citynum].border
        const lbackcolor=citycolors[citynum].background
        const lmbackcolor=citycolors[citynum].mbackground
        map.addSource(baselayerid, {
          type: 'geojson',
          data
        });
        map.addLayer(
          {
            id: baselayerid+'-fills',
            type: 'fill',
            source: baselayerid,
            paint: {
              'fill-color': lbackcolor,
              'fill-opacity': 1,
              'fill-outline-color': lbordercolor
            },
            filter: ['==', 'statu', 0],
          },
        );
        map.addLayer(
          {
            id: baselayerid+'-mint-fills',
            type: 'fill',
            source: baselayerid,
            paint: {
              'fill-color': lmbackcolor,
              'fill-opacity': 1,
              'fill-outline-color': lbordercolor
            },
            filter: ['==', 'statu', 9],
          },
        );
        map.addLayer(
          {
            id: baselayerid+'-mint-fills-back',
            type: 'fill',
            source: baselayerid,
            paint: {
              'fill-color': lmbackcolor,
              'fill-opacity': 1,
              'fill-outline-color': lbordercolor
            },
            filter: ['all',
            ['any', ['==', 'statu', 1], ['==', 'statu', 2], ['==', 'statu', 3], ['==', 'statu', 4]]
            ],
          },
        );
        map.addLayer({
          id: baselayerid+'-mint-labels',
          type: 'symbol',
          source: baselayerid,
          layout: {
          'text-field': ['get', 'id'],
          'text-size': 14
          },
          paint: {
          'text-color': '#ffffff',
          },
          filter: ['all',
          ['any', ['==', 'statu', 1], ['==', 'statu', 4]]
          ],
          });
          alllabelsid.push({layerid:baselayerid+'-mint-labels',textget:'id'})
          map.addLayer({
            id: baselayerid+'-mint-labels-ldes',
            type: 'symbol',
            source: baselayerid,
            layout: {
            'text-field': ['get', 'ldes'],
            'text-size': 14
            },
            paint: {
            'text-color': '#ffffff',
            },
            filter: ['==', 'statu', 2] 
            });
            alllabelsid.push({layerid:baselayerid+'-mint-labels-ldes',textget:'ldes'})
            map.addLayer({
              id: baselayerid+'-mint-labels-lrea',
              type: 'symbol',
              source: baselayerid,
              layout: {
              'text-field': ['get', 'lrea'],
              'text-size': 14
              },
              paint: {
              'text-color': '#ffffff',
              },
              filter: ['==', 'statu', 3] 
              });
              alllabelsid.push({layerid:baselayerid+'-mint-labels-lrea',textget:'lrea'})
        map.addLayer({
          id: baselayerid+'-fills-hover',
          type: 'fill',
          source: baselayerid,
          layout: {},
          paint: {
            'fill-color': '#000000',
            'fill-opacity': 0.3,
          },
          filter: ['==', 'id', ''],
        });     
             
        map.addLayer(
          {
            id: baselayerid+'-sold-lines',
            type: 'line',
            source: baselayerid,
            paint: {
              'line-color': '#e95b5a',
              'line-width': 3
            },
            filter: ['==', 'sold', 1],
          },
        );
        // map.addLayer({
        //   id: baselayerid+'sold-fills',
        //   type: 'symbol',
        //   source: baselayerid,
        //   layout: {'icon-image': 'soldimgsource',
        //   'icon-anchor': 'bottom-right',
        //   'icon-offset': [-50, 25],
        //   },
        //   filter: ['==', 'sold', 0],
        // });
        //========================================       
      }
    }
//--------------------------------render end========================
map.addSource('my-lands', {
  type: 'geojson',
  data:mylandsgeo,
});
map.addLayer(
  {
    id: 'my-lands-fills',
    type: 'fill',
    source: 'my-lands',
    paint: {
      'fill-color': '#00ff00',
      'fill-opacity': 0,
    },
  },
);

//渲染网格end=====================    

      //==============选择========================================================
      let selectedFeatureId = null;
      map.on('click', (e) => {
        //=========select mylands end=================
        const popfeatures = map.queryRenderedFeatures(e.point, { layers: ['quarks-mint-fills-back','neutrons-mint-fills-back','electrons-mint-fills-back','photons-mint-fills-back','infinitys-mint-fills-back','sophons-mint-fills-back','islands-mint-fills-back'] });
        if (popfeatures.length>0) {
                const coord=popfeatures[0].geometry.coordinates[0]
                const latTopLeft = coord[0][1];
                const latBottomLeft = coord[3][1];
                const midLat = (latTopLeft + latBottomLeft) / 2;
                const lng = coord[0][0];
          const lefttoplng=[lng, midLat]
          const poplid=popfeatures[0].properties.id
          let poplrea=popfeatures[0].properties.lrea,popldes=popfeatures[0].properties.ldes,popmarketl=popfeatures[0].properties.marketl,poprow=popfeatures[0].properties.lrow,popcol=popfeatures[0].properties.lcol
          let upfeatures,upfeature
          const firstnum = Number(poplid.toString()[0]) || 0;
          if(alllands[firstnum]){
              upfeatures=alllands[firstnum].features
              if(upfeatures){
                upfeature = upfeatures.find(f => f.properties.id === Number(poplid));
                  if (upfeature) {
                    poplrea=upfeature.properties.lrea
                    popldes=upfeature.properties.ldes
                    popmarketl=upfeature.properties.marketl
                    poprow=upfeature.properties.lrow
                    popcol=upfeature.properties.lcol
                  }
              }

          }
          
          let marketbutton='',popshareid=poplid
          if (poplrea !== null && typeof poplrea === 'string' && poplrea.length > 0) {
            popshareid= poplrea.replace(/\+/g, '');
          }
          if(popmarketl.length>0){
            marketbutton=`<button class="pop-custom-button" onclick="window.open('${popmarketl}')">Buy</button>`
          }
          const sharetext = encodeURIComponent('https://atomicals.land?l='+popshareid);
          const twitterUrl = 'https://twitter.com/intent/tweet?text=' + sharetext;
          // new mapboxgl.Popup({anchor:'right',closeButton :true})
          popupRef.current
          .setLngLat(lefttoplng)
          .setHTML(`<h3>${poplid}</h3><p>${poprow}×${popcol}</p><p>${poplrea}</p><p>${popldes}</p><p><button class="pop-custom-button" onclick="window.open('${twitterUrl}')">Share</button>${marketbutton}</p>`)
          .addTo(map);
        }
        //==========select mylands==============
        const mylandfeatures = map.queryRenderedFeatures(e.point, { layers: ['my-lands-fills'] });
        if (!mylandfeatures.length) {
          setShowmyset(false);
        }
        //=========select mylands end=================
        const features = map.queryRenderedFeatures(e.point, { layers: ['quarks-fills','neutrons-fills','electrons-fills','photons-fills','infinitys-fills','sophons-fills'] });
        if (!features.length) {
          setShow(false);
        }
        if (!mylandfeatures.length && !features.length) {
          return;
        }
        let firstfeature,fearows=1,feacols=1
        if(features.length){
          selectedFeatureId = features[0].properties.id;
          map.setFilter('quarks-fills-hover', ['==', 'id', selectedFeatureId]);
          map.setFilter('neutrons-fills-hover', ['==', 'id', selectedFeatureId]);
          map.setFilter('electrons-fills-hover', ['==', 'id', selectedFeatureId]);
          map.setFilter('photons-fills-hover', ['==', 'id', selectedFeatureId]);
          map.setFilter('infinitys-fills-hover', ['==', 'id', selectedFeatureId]);
          map.setFilter('sophons-fills-hover', ['==', 'id', selectedFeatureId]);
          setcurrSelectedFeatures([[selectedFeatureId,features[0].geometry.coordinates]]);
          firstfeature=features[0];
          setShowmyset(false);
        }
        if(mylandfeatures.length){
          selectedFeatureId = mylandfeatures[0].properties.id;
          setcurrSelectedFeatures([[selectedFeatureId,mylandfeatures[0].geometry.coordinates]]);
          firstfeature=mylandfeatures[0];
          setShowmyset(true);
          
          const fsellid=firstfeature.properties.id
          const firstnum = Number(fsellid.toString()[0]) || 0;
                if(alllands[firstnum]){
                  const fselfeatures=alllands[firstnum].features;
                  if(fselfeatures){
                    const fselfeature = fselfeatures.find(f => f.properties.id === Number(fsellid));
                    if (fselfeature) {
                      fearows=fselfeature.properties.lrow
                      feacols=fselfeature.properties.lcol
                    }
                  }
                }
        }
          
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
        for (const ring of firstfeature.geometry.coordinates) {
          for (const point of ring) {
            const {x, y} = map.project(point);
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
          }
        }
        setRowCol({rows:fearows,cols:feacols})
        openDragResizable(minX,minY, (maxX - minX)/feacols, (maxY - minY)/fearows);
      });

      function openDragResizable(x, y, width, height) {
        const maxWidth=width*3;
        const maxHeight=height*3;
        // console.log(width, height ,maxWidth, maxHeight)
        setPosition({ x, y });
        setSize({ width, height });
        setminSize({ width, height });
        setmaxSize({ maxWidth, maxHeight });
        setShow(true);
      }
      //==============select========================================================
      setMap(map);
      setIsMapLoaded(true);
    });
    
    map.on('boxzoomstart', (e) => {
      setShow(false);
    });
    map.on('zoomstart', (e) => {
      setShow(false);
    });
    map.on('move', (e) => {
      setShow(false);
      if (map.getZoom() > 9) {
        renderimg()
      }
    });
    map.on('zoom', () => {
      if (map.getZoom() < 9) {
        for(let li=0;li<alllabelsid.length;li++){
          map.setLayoutProperty(alllabelsid[li].layerid, 'text-field', '');
        }
        //===========load island img===============
        if (map.getZoom() >5) {
          setRenderisland(true)
        }
        //===========load island img end============
      } else {
        for(let li=0;li<alllabelsid.length;li++){
          map.setLayoutProperty(alllabelsid[li].layerid, 'text-field', ['get', alllabelsid[li].textget]);
        }
        //===========load img===============
          renderimg()
        //===========load img end============
      }
    });

    const renderimg=()=>{
      const markerfeatures = map.queryRenderedFeatures({ layers: ['quarks-mint-fills-back','neutrons-mint-fills-back','electrons-mint-fills-back','photons-mint-fills-back','infinitys-mint-fills-back','sophons-mint-fills-back'] });
      if(markerfeatures.length){
        let firstnum=1,selid=0,selfeatures=null,selfeature=null,selCoordinate = null
            for(let mi=0;mi<markerfeatures.length;mi++){
              selid=markerfeatures[mi].properties.id
              firstnum = Number(selid.toString()[0]) || 0;
                if(alllands[firstnum]){
                  selfeatures=alllands[firstnum].features;
                  if(selfeatures){
                    // selfeature = selfeatures.find(f => f.properties.id === Number(selid));
                    selfeature = findFeature(selfeatures, selid)
                    if (selfeature) {
                      if (selfeature.properties.statu===4){
                        if (map.getLayer('image-layer-' + selid)) {
                          continue
                        }
                          selCoordinate = selfeature.geometry.coordinates[0][0];
                            map.addSource('image-source-' + selid, {
                              'type': 'image',
                              'url': selfeature.properties.limg,
                              'coordinates': selCoordinate.slice(0, 4)
                            });
                            map.addLayer({
                              id: 'image-layer-' + selid,
                              'type': 'raster',
                              'source': 'image-source-' + selid,
                              'paint': {
                                'raster-fade-duration': 0
                              }
                            });
                          }else{
                            continue
                          }

                    }
                  }
                }

            }
      }
    }
    function findFeature(features, id) {
      return features.find(f => f.properties.id === Number(id));
    }
    map.on('style.load', () => {
      // Custom atmosphere styling
      map.setFog({
      'color': 'rgb(82,66,116)', // Pink fog / lower atmosphere
      'high-color': 'rgb(42,44,191)', // Blue sky / upper atmosphere36, 92, 223
      'horizon-blend': 0.4 // Exaggerate atmosphere (default is .1)
      });
      map.addControl(new mapboxgl.FullscreenControl());
      map.addControl(new mapboxgl.NavigationControl());
      
    });
    // Clean up on unmount
    return () => map.remove();
  }, []);
  useEffect(() => {
    if (isMapLoaded && alllands[8].features) {
      if(renderisland){
        const isfeatures=alllands[8].features;
        let selfeature
        for(let ii=0;ii<isfeatures.length;ii++){
          selfeature=isfeatures[ii]
          if (selfeature.properties.limg!==''){
            // console.log(selfeature)
             //--------------------
             const el = document.createElement('div');
            el.id='m'+selfeature.properties.id;
            el.style.backgroundSize='cover';
            el.style.backgroundImage = `url(${selfeature.properties.limg})`;
            el.style.width = `30px`;
            el.style.height = `30px`;
            el.style.borderRadius='50%';
            const coordinates = selfeature.geometry.coordinates[0];
            const bounds = new mapboxgl.LngLatBounds();
            coordinates[0].forEach(coord => {
              bounds.extend(coord);
            });
            const center = bounds.getCenter();
            const marker=new mapboxgl.Marker({
                    element: el,
                    pitchAlignment: 'viewport',
                    rotationAlignment:'viewport',//'viewport',//'horizon',
                    // anchor: 'center'
                    anchor: 'bottom',
                  })
                  // .setLngLat([bounds.getWest(), bounds.getSouth()])
                  .setLngLat([center.lng, center.lat])
                  .addTo(map);
                  const initialZoom = map.getZoom()
                  map.on('zoom', () => {
                          const zoom = map.getZoom();
                          const scale = Math.pow(1.1, zoom -initialZoom);
                          const width = 30 * scale;
                          const height = 30 * scale;
                          el.style.width = `${width}px`;
                          el.style.height = `${height}px`;
                          
                          if (zoom < 5) {
                            marker.getElement().style.display = 'none';
                            
                          } else {
                            marker.getElement().style.display = 'block';
                          }
                        });
            // map.loadImage(selfeature.properties.limg, function(error, image) {
            //   if (error) throw error;
            //   map.addImage('image-id', image);
            //   map.addLayer({
            //       'id': 'polygon-layer',
            //       'type': 'fill',
            //       'source': 'islands',
            //       'paint': {
            //       'fill-pattern': 'image-id'
            //       },
            //       filter: ['==', 'statu', 4] 
            //   });
              // map.addLayer({
              //   'id': 'marker-layer',
              //   'type': 'symbol',
              //   'source': 'islands',
              //   'layout': {
              //   'icon-image': 'image-id',
              //   'icon-size': 1
              //   },
              //   filter: ['==', 'statu', 4] 
              //   });

            // });
            //-----------------------
          }
        }
      }
    }else{
      setRenderisland(false)
    }
  }, [renderisland]);  
  useEffect(() => {
    if (isMapLoaded && mylands.length>0) {
      let firstnum =0,mylandgeo=[],querythisland=[];
      let allfeatures,features,feature;
      for(let i=0;i<mylands.length;i++){
        firstnum = Number(mylands[i].$dmitem.toString()[0]) || 0;
        allfeatures=alllands[firstnum];
        if(allfeatures){
          features=allfeatures.features
          feature = features.find(f => f.properties.id === Number(mylands[i].$dmitem));
          if (feature) {
            mylandgeo.push(feature)
            if(feature.properties.statu===0){
              querythisland.push(feature.properties.id)
            }
          }
        }
      }
      setMylandsgeo({type: 'FeatureCollection', features: mylandgeo})
      sendQueryland(querythisland)
    }else{
      setMylandsgeo({type: 'FeatureCollection', features: []})
    }
  }, [mylands]);
  useEffect(() => {
    if(map && map.isStyleLoaded()){
      const mylandsource=map.getSource('my-lands')
      if(mylandsource){
        mylandsource.setData(mylandsgeo);
        if (map.getLayer('my-lands-fills')) {
          map.removeLayer('my-lands-fills');
        }
        if (map.getLayer('my-lands-borders')) {
          map.removeLayer('my-lands-borders');
        }
        map.addLayer(
          {
            id: 'my-lands-fills',
            type: 'fill',
            source: 'my-lands',
            paint: {
              'fill-color': '#00ff00',
              'fill-opacity': 0,
            },
          },
        );
        map.addLayer({
          id: 'my-lands-borders',
          type: 'line',
          source: 'my-lands',
          layout: {},
          paint: {
            'line-color': '#00ff00',
            'line-width': 2,
          },
        });
      }
    }
  }, [map,mylandsgeo]);
  const renderSetupLands=(dselectedfeatures)=>{
    const firstnum = Number(dselectedfeatures[0][0].toString()[0]) || 0;
    const baselayerid=citycolors[firstnum].layerid
    if(baselayerid!==''){
        axios.get(geourl+baselayerid)
              .then(response => {
              const data = response.data;
              alllands[firstnum]=data;
              const selectfeatures=[]
              for(let di=0;di<dselectedfeatures.length;di++){
                const feature = data.features.find(f => f.properties.id === Number(dselectedfeatures[di][0]));
                if (feature) {
                  selectfeatures.push(feature)
                }
              }
              const selectdata={type:"FeatureCollection",features:selectfeatures}
              renderUpdateLayer(selectdata,firstnum)
        })
          .catch(error => {
          console.error(error);
       });
    }
    const renderUpdateLayer=(data,citynum)=>{
      // console.log(data)
      if(data){
        const basecity=citycolors[citynum].layerid
        const lbordercolor=citycolors[citynum].border
        const lmbackcolor=citycolors[citynum].mbackground
        const randomNumber = Math.floor(Math.random() * 90000) + 10000;
        const baselayerid=basecity+'up'+randomNumber
        map.addSource(baselayerid, {
          type: 'geojson',
          data
        });
        
        map.addLayer(
          {
            id: baselayerid+'-mint-fills',
            type: 'fill',
            source: baselayerid,
            paint: {
              'fill-color': lmbackcolor,
              'fill-opacity': 1,
              'fill-outline-color': lbordercolor
            },
            filter: ['==', 'statu', 9],
          },
        );
        map.addLayer(
          {
            id: baselayerid+'-mint-fills-back',
            type: 'fill',
            source: baselayerid,
            paint: {
              'fill-color': lmbackcolor,
              'fill-opacity': 1,
              'fill-outline-color': lbordercolor
            },
            filter: ['all',
            ['any', ['==', 'statu', 1], ['==', 'statu', 2], ['==', 'statu', 3], ['==', 'statu', 4]]
            ],
          },
        );
        map.addLayer({
          id: baselayerid+'-mint-labels',
          type: 'symbol',
          source: baselayerid,
          layout: {
          'text-field': ['get', 'id'],
          'text-size': 14
          },
          paint: {
          'text-color': '#ffffff',
          },
          filter: ['all',
          ['any', ['==', 'statu', 1], ['==', 'statu', 4]]
          ],
          });
          alllabelsid.push({layerid:baselayerid+'-mint-labels',textget:'id'})
          map.addLayer({
            id: baselayerid+'-mint-labels-ldes',
            type: 'symbol',
            source: baselayerid,
            layout: {
            'text-field': ['get', 'ldes'],
            'text-size': 14
            },
            paint: {
            'text-color': '#ffffff',
            },
            filter: ['==', 'statu', 2] 
            });
            alllabelsid.push({layerid:baselayerid+'-mint-labels-ldes',textget:'ldes'})
            map.addLayer({
              id: baselayerid+'-mint-labels-lrea',
              type: 'symbol',
              source: baselayerid,
              layout: {
              'text-field': ['get', 'lrea'],
              'text-size': 14
              },
              paint: {
              'text-color': '#ffffff',
              },
              filter: ['==', 'statu', 3] 
              });
              alllabelsid.push({layerid:baselayerid+'-mint-labels-lrea',textget:'lrea'})
      
              map.addLayer(
                {
                  id: baselayerid+'-sold-lines',
                  type: 'line',
                  source: baselayerid,
                  paint: {
                    'line-color': '#e95b5a',
                    'line-width': 3
                  },
                  filter: ['==', 'sold', 1],
                },
              );
        //========================================
        data.features.forEach(feature => {
          const randomNumber = Math.floor(Math.random() * 90000) + 10000;
          let selid=feature.properties.id
                if (map.getLayer('image-layer-' + selid)) {
                  map.removeLayer('image-layer-' + selid)
                }
                
                // if('image-source-' + selid){
                //   map.removeSource('image-source-' + selid)
                // }
          if (feature.properties.statu === 4) {
            const coordinates = feature.geometry.coordinates[0][0];
                        map.addSource('image-source-' + selid+randomNumber, {
                          'type': 'image',
                          'url': feature.properties.limg,
                          'coordinates': coordinates.slice(0, 4)
                        });
                        map.addLayer({
                          id: 'image-layer-' + selid,
                          'type': 'raster',
                          'source': 'image-source-' + selid+randomNumber,
                          'paint': {
                            'raster-fade-duration': 0
                          }
                        });
          }
        });
        //======================================================
        popupRef.current.remove()     
      }
    }

  }

  useEffect(() => {
    if(isMapLoaded && alllandsload===7){
    if(urllid){
      flyTo(urllid)
    }
    }
  }, [alllandsload]);

  const flyTo = (flyValue) => {
    let flylid=flyValue,flyzoom=9,findfeature=null
    if(isNaN(+flyValue)){
      const options=[{cname:'Quark city',clid:10966},{cname:'Neutron city',clid:21271},{cname:'Electron city',clid:32185},{cname:'Photon city',clid:51385},{cname:'Infinity city',clid:62043},{cname:'Sophon continent',clid:92710}]
      const cityitem = options.find(f => f.cname === flyValue);
      if(cityitem){
        flylid=cityitem.clid
        flyzoom=7
      }else{
        //=====find realm ==========
        let realmfeatures=null,realmfeature=null
        for(let ai=1;ai<10;ai++){
          if(alllands[ai]){
            realmfeatures=alllands[ai].features
            if(realmfeatures){
              realmfeature=realmfeatures.find(f => f.properties.lrea === '+'+flyValue)
              if(realmfeature){
                findfeature=realmfeature
                break
              }
            }
          }
        }
        if(findfeature){
          if (isMapLoaded) {
            map.flyTo({
              center: findfeature.geometry.coordinates[0][0][0],
              zoom: flyzoom,
              speed: 0.2,
              curve: 2,
              duration: 12000,
              essential: true
            });
          }
        }else{
          flyerror()
        }
      }
    }
    //===================
    if(!findfeature){
      const firstnum = Number(flylid.toString()[0]) || 0;
      if(alllands[firstnum]){
        const features=alllands[firstnum].features;
        if(features){
          let firstCoordinate = null;
          const feature = features.find(f => f.properties.id === Number(flylid));
          if (feature) {
            firstCoordinate = feature.geometry.coordinates[0][0][0];
            if (isMapLoaded) {
              map.flyTo({
                center: firstCoordinate,
                zoom: flyzoom,
                speed: 0.2,
                curve: 2,
                duration: 12000,
                essential: true
              });
            }
          }else{
            flyerror()
          }
        }
      }
    }
  };
 
   return (
    <div>
      <TopBar flyTo={flyTo}/>
      {contextHolder}
      <Dmint ref={dmintRef} />
      <Setupland ref={setupRef} alllands={alllands} renderSetupLands={renderSetupLands}/>
      <div ref={mapContainerRef} className="map-container" />
      {show && (
        <Rnd
        disableDragging={true}
          style={{
            overflow: 'visible',
            position: 'relative',
            border: 'dashed 1px #000000'
          }}
          enableResizing={enableResizing}
          size={size}
          minWidth={minsize.width}
          minHeight={minsize.height}
          maxWidth={maxsize.maxWidth}
          maxHeight={maxsize.maxHeight}
          position={position}
          // onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
          onResizeStop={(e, direction, ref, delta, position) => {
            if(isIrregularLand){
              const oneFeatureIds = currSelectedFeatures.map(feature => feature[0]);
              map.setFilter('quarks-fills-hover', ['in', 'id', ...oneFeatureIds]);
              map.setFilter('neutrons-fills-hover', ['in', 'id', ...oneFeatureIds]);
              map.setFilter('electrons-fills-hover', ['in', 'id', ...oneFeatureIds]);
              map.setFilter('photons-fills-hover', ['in', 'id', ...oneFeatureIds]);
              map.setFilter('infinitys-fills-hover', ['in', 'id', ...oneFeatureIds]);
              map.setFilter('sophons-fills-hover', ['in', 'id', ...oneFeatureIds]);
              
              setIsIrregularLand(false);
            }
            let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
              for (const rings of currSelectedFeatures) {
                for (const ring of rings[1]) {
                  for (const point of ring) {
                    const {x, y} = map.project(point);
                    minX = Math.min(minX, x);
                    minY = Math.min(minY, y);
                    maxX = Math.max(maxX, x);
                    maxY = Math.max(maxY, y);
                  }
                }
              }
              if(currSelectedFeatures.length===1 && (rowcol.rows!==1 || rowcol.cols!==1)){
                setSize({
                  width: (maxX - minX)/rowcol.cols,
                  height: (maxY - minY)/rowcol.cols
                });
              }else{
                setSize({
                  width: maxX - minX,
                  height: maxY - minY
                });
              }
          }}
          onResize={(e, direction, ref, delta, position) => {
            setSize({
              width: ref.style.width,
              height: ref.style.height
            });
            setPosition({ x: position.x, y: position.y });
            const topLeft =[position.x+10, position.y+10];
            // const topRight = [position.x + ref.offsetWidth, position.y];
            // const bottomLeft = [position.x, position.y + ref.offsetHeight];
            const bottomRight = [position.x + ref.offsetWidth-10, position.y + ref.offsetHeight-10];

            let features=null
            if(showmyset){
              features = map.queryRenderedFeatures([topLeft,  bottomRight], { layers: ['my-lands-fills'] });
            }else{
             features = map.queryRenderedFeatures([topLeft,  bottomRight], { layers: ['quarks-fills','neutrons-fills','electrons-fills','photons-fills','infinitys-fills','sophons-fills'] });
            }
             const uniqueFeatures = Array.from(new Set(features.map(feature => feature.properties.id)))
            .map(id => {
              return features.find(feature => feature.properties.id === id);
            });
            let newFeaturesArray = uniqueFeatures.slice(0,9).map(feature => [feature.properties.id, feature.geometry.coordinates]);
            function removeDuplicates(array) {
              let uniqueArray = array.filter((item, index) => {
                return JSON.stringify(array.slice(index + 1)).indexOf(JSON.stringify(item)) === -1;
              });
              return uniqueArray;
            }
            let vertexCount = {};
            for (let feature of newFeaturesArray) {
              let coordinates = removeDuplicates(feature[1][0]);
              for (let vertex of coordinates) {
                let key = JSON.stringify(vertex);
                if (key in vertexCount) {
                  vertexCount[key]++;
                } else {
                  vertexCount[key] = 1;
                }
              }
            }
            let singleSharedVertexCount = 0;
            for (let key in vertexCount) {
              if (vertexCount[key] === 1) {
                singleSharedVertexCount++;
              }
            }
            const selectedFeatureIds = newFeaturesArray.map(feature => feature[0]);
            map.setFilter('quarks-fills-hover', ['in', 'id', ...selectedFeatureIds]);
            map.setFilter('neutrons-fills-hover', ['in', 'id', ...selectedFeatureIds]);
            map.setFilter('electrons-fills-hover', ['in', 'id', ...selectedFeatureIds]);
            map.setFilter('photons-fills-hover', ['in', 'id', ...selectedFeatureIds]);
            map.setFilter('infinitys-fills-hover', ['in', 'id', ...selectedFeatureIds]);
            map.setFilter('sophons-fills-hover', ['in', 'id', ...selectedFeatureIds]);
            if(singleSharedVertexCount===4){
              setIsIrregularLand(false);
              setcurrSelectedFeatures(newFeaturesArray);
            }else{
              setIsIrregularLand(true);
            }
          }}
        >
          {isIrregularLand &&<div style={{position: 'absolute', left:'0px',top: '-63px',width:'280px',fontSize:'14px', backgroundColor: 'white', padding: '5px', borderRadius: '4px'}}>
            <small style={{color: 'red'}}>Note: Irregular land cannot be selected.</small>
          </div>}
          <div style={{border: '0px',position: 'absolute', left:'0px',top: '-27px', width: '100%', height: '25px' }} >
            {showmyset?<button style={{width: '56px', height: '25px',background:'#fff',textAlign:'center',borderRadius:'4px'}}
            onClick={() => {
                // console.log("currselect:",currSelectedFeatures)
                const converFeatures=MapProject(map,currSelectedFeatures)
                // console.log("conver:",converFeatures)
                dispatch({
                  type: 'SET_DSELECTEDFEATURES',
                  payload: converFeatures,
                });
                if(dconnected){
                  if(signsession.address && signsession.address===daddress){
                    handleSetupShowModal()
                  }else{
                      axios.get(geourl+"nonce")
                      .then(async (response) => {
                        const data = response.data;
                        try {
                          const messagea='Welcome to atomicals.land!\nI accept the AtomicalsLand Terms of Service: https://atomicals.land\n\nSign in Atomicals Land with\n'+daddress
                          const messageb='\n\nNonce:'+data.nonce
                          let ressignature = await window.wizz.signMessage(messagea+messageb);
                          try {
                            const pubKey = await window.wizz.getPublicKey();
                            const sessionjson={
                              address:daddress,
                              pubkey:pubKey,
                              signature: ressignature,
                              message: messagea+messageb
                              }
                            const response = await axios.post(geourl+"verify", 
                            JSON.stringify(sessionjson), {
                              headers: {
                              'Content-Type': 'application/json'
                              }
                            });
                              // console.log("verify:",response.data);
                              if(response.data.verified){
                                dispatch({
                                  type: 'SET_SIGNSESSION',
                                  payload: sessionjson,
                                });
                                handleSetupShowModal()
                              }else{
                                verifyerror()
                              }
                            } catch (error) {
                              verifyerror()
                            }
                          
                        } catch (e) {
                          verifyerror()
                        }
                      })
                      .catch(error => {
                        verifyerror()
                      });
                    }
                }else{
                  dispatch({
                    type: 'SET_MINTOPENWALLET',
                    payload: true,
                  });
                }
              }
            }
            >Setup</button>:
            <button style={{width: '56px', height: '25px',background:'#fff',textAlign:'center',borderRadius:'4px'}}
            onClick={() => {
                // console.log("currselect:",currSelectedFeatures)
                const converFeatures=MapProject(map,currSelectedFeatures)
                // console.log("conver:",converFeatures)
                dispatch({
                  type: 'SET_DSELECTEDFEATURES',
                  payload: converFeatures,
                });
                if(dconnected){
                  // if(dwallet==='wizz'){
                  //   // console.log('wizz:',window.wizz)
                  // }
                  // if(dwallet==='unisat'){
                  //   console.log('unisat',window.unisat)
                  // }
                  handleDmintShowModal()
                }else{
                  dispatch({
                    type: 'SET_MINTOPENWALLET',
                    payload: true,
                  });
                }
              }
            }
            >Dmint</button>}
            
          </div>
          <div style={{position:'relative',overflow:'hidden',wordWrap:'break-word', width: '100%', height: '100%' ,fontSize:'10px',color:'#ffffff', lineHeight:'1.2'}} >
          {!showmyset &&<div><p>{currSelectedFeatures.length * 10000}m&sup2;</p>
          <p>={currSelectedFeatures.length * 107639}ft&sup2;</p></div>}
            <div style={{position: 'absolute', right: '0px', bottom: '0px', width: '5px', height: '5px', background: '#ffffff'}}></div>
          </div>
        </Rnd>
      )}

    </div>
  );
};

export default Map;
function MapProject(map,rectangles) {
  const transformedRectangles = [];
  rectangles.forEach((rectangle) => {
    const transformedCoordinates = [];
    rectangle[1][0].forEach((point) => {
      const {x, y}=map.project(point);
      const transformedPoint = [x,y];
      transformedCoordinates.push(transformedPoint);
    });
    transformedRectangles.push([rectangle[0], [transformedCoordinates]]);
  });
 return transformedRectangles;
}
async function sendQueryland(querythisland){
  if(querythisland.length>0){
    try {
      await axios.post(geourl+"querythisland", JSON.stringify(querythisland), {
        headers: {
        'Content-Type': 'application/json'
        }
      });
        
      } catch (error) {
        // console.error(error);
      }
       
  }
}