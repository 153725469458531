import React, { useState,useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from "react-redux";
import { Button, ConfigProvider,Flex, Input ,Select,Modal,Radio, message,Upload  } from 'antd';
import ImgCrop from 'antd-img-crop';
import SetupRectangles from './SetupRectangles';
import axios from 'axios';
import { geourl } from '../networkConfig.js';
import '../assets/Dmint.css'
const Setupland = forwardRef((props, ref) => {
  const {alllands,renderSetupLands}=props
  const dselectedfeatures = useSelector(state => state.dselectedfeatures);
  const signsession = useSelector(state => state.signsession);
  const myrealms = useSelector(state => state.myrealms);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [realmoption, setRealmoption] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [getid,setGetid]=useState(0)
  const [curraspect,setCurraspect]=useState(1)
  const [currrowcol,setCurrrowcol]=useState({rows:1,cols:1})
  const [landDesc, setLandDesc] = useState('');
  const [bandRealm, setBandRealm] = useState('');
  const [marketLink, setMarketlink] = useState('');
  const [defaultRadio,setDefaultRadio]=useState(1)
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    const reallist=[{value:'',label:''}]
    if(myrealms.length>0){
      for(let ri=0;ri<myrealms.length;ri++){
        reallist.push({value:myrealms[ri].$realm,label:'+'+myrealms[ri].$realm})
      }
    }else{
      reallist.push({value:'+',label:'none realm name',disabled: true})
    }
    setRealmoption(reallist)
  }, [myrealms]);
  useEffect(() => {
    const topLeftRectangle = dselectedfeatures.reduce((topLeft, current) => {
      const currentMinX = Math.min(...current[1][0].map(coord => coord[0]));
      const currentMinY = Math.min(...current[1][0].map(coord => coord[1]));
      if (currentMinX < topLeft.minX || currentMinY < topLeft.minY) {
        return { lid: current[0], minX: currentMinX, minY: currentMinY };
      }
      return topLeft;
      }, { lid: null, minX: Infinity, minY: Infinity });
      if(topLeftRectangle.lid){
        setGetid(topLeftRectangle.lid)
      }
      const rows = new Set(dselectedfeatures.map(item => Math.min(...item[1][0].map(coord => coord[1])))).size;
      const cols = new Set(dselectedfeatures.map(item => Math.min(...item[1][0].map(coord => coord[0])))).size;
      if(rows>0 && cols>0){
          let caspect=1
          if(rows===1){
            caspect=cols
          }
          if(rows===2 && cols===1){
            caspect=0.5
          }
          if(rows===2 && cols===3){
            caspect=1.5
          }
          if(rows===3 && cols===1){
            caspect=0.333
          }
          if(rows===3 && cols===2){
            caspect=0.666
          }
          setCurraspect(caspect)
          setCurrrowcol({rows:rows,cols:cols})
      }
  }, [dselectedfeatures]);
  
  useEffect(() => {
    if(getid>0){
    let firstnum =0
      let allfeatures,features,feature;
      
        firstnum = Number(getid.toString()[0]) || 0;
        allfeatures=alllands[firstnum];
        if(allfeatures){
          features=allfeatures.features
          feature = features.find(f => f.properties.id === Number(getid));
          if (feature) {
            
            setLandDesc(feature.properties.ldes)
            setMarketlink(feature.properties.marketl)
            setDefaultRadio(feature.properties.statu)
            const reastr=feature.properties.lrea
            const newreaStr = reastr.replace(/\+/g, '')
            setBandRealm(newreaStr)
            const imgstr=feature.properties.limg
            if(imgstr.includes('/uploads/')){
              const oldfilename=imgstr.replace(/\/uploads\//g, '')
              const randomNumber = Math.floor(Math.random() * 90000) + 10000;
              const newImage = {
                uid: 'i'+randomNumber,
                name: oldfilename,
                response: {succ: true, fileName:oldfilename},
                status: 'done',
                url: feature.properties.limg,
                };
                setFileList([newImage]);
            }else{
              setFileList([])
            }
          }
        }
      }
  }, [getid]);
 
  useImperativeHandle(ref, () => ({
    showModal,
    }));
  const showModal = () => {
    setOpen(true);
  };
  const inputonChange = (e) => {
    setLandDesc(e.target.value)
  };
  const marketinputonChange = (e) => {
    setMarketlink(e.target.value)
  };
  const handleChange = (value) => {
    setBandRealm(value)
  };
  const onRadioChange = (e) => {
    setDefaultRadio(e.target.value)
  };
  const showerror = (errstr) => {
    messageApi.open({
      type: 'error',
      content: errstr,
    });
  };

  const onChange = ({ file, fileList, event }) => {
    setFileList(fileList);
    if (file.status === 'done') {
      if(file.response.succ){

      }else{
        showerror('File upload failed');
      }
    } else if (file.status === 'error') {
      showerror('File upload failed');
    }
    };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' ||  file.type === 'image/png'||  file.type === 'image/gif';
      if (!isJpgOrPng) {
        showerror('You can only upload JPG/PNG/GIF file!');
      return Upload.LIST_IGNORE;
      }
      const isLt100K = file.size / 1024 < 20;
      if (!isLt100K) {
        showerror('Image must smaller than 20KB!');
      return Upload.LIST_IGNORE;
      }
      return new Promise((resolve, reject) => {
        resolve();
      });
  };
    
  const handleSave = async() => {
    setLoading(true)
    // console.log(landDesc,bandRealm,fileList,marketLink,currrowcol,defaultRadio)
    // renderSetupLands(dselectedfeatures)
    // setOpen(false)
    // setLoading(false)
    // return
    if(isNullOrEmpty(landDesc) || isNullOrEmpty(bandRealm) ){
      showerror('illegal data!');
    }else{
      // console.log(dselectedfeatures,signsession)
      try {
        let landImg=''
        if(fileList.length>0){
          if(fileList[0].status==='done'){
              landImg=fileList[0].response.fileName
          }
        }
        const savejson={
          sign:signsession,
          features:dselectedfeatures,
          savedata:{landdesc:landDesc,
                    bandrealm:bandRealm,
                    marketlink:marketLink,
                    landimg:landImg,
                    rowcol:currrowcol,
                    defadis:defaultRadio
                  }
          }
        const response = await axios.post(geourl+"setupsave", 
        JSON.stringify(savejson), {
          headers: {
          'Content-Type': 'application/json'
          }
        });
          setLoading(false)
          // console.log("save:",response.data);
          if(response.data.succ){
            renderSetupLands(dselectedfeatures)
            setOpen(false)
          }else{
            showerror(response.data.mes);
          }
        } catch (error) {
          setLoading(false)
          // console.log(error)
          showerror('Network Error');
        }
    }
  }
  const handleOk = () => {
     
  };
  
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <ConfigProvider
            theme={{
              token: {
                // Seed Token，影响范围大
                colorPrimary: '#d9d9d9',
                // borderRadius: 2,

                // 派生变量，影响范围小
                // colorBgContainer: '#f6ffed',
              },
            }}
          >
      <Modal
        open={open}
        title="Setup..."
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="save" className="custom-button" loading={loading} onClick={handleSave} >
            Save
          </Button>,
          <Button key="merge" disabled>
          Merge
        </Button>,
        ]}
      >
        {contextHolder}
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '190px' }}>
            <SetupRectangles rectangles={dselectedfeatures}/>
          </div>
          <div style={{ flex: 1 }}>
          <Flex vertical gap={6}>
            <Input showCount maxLength={20} onChange={inputonChange} value={landDesc} placeholder="description"/>
            <Select
              defaultValue=''
              value={bandRealm}
              style={{
                width: '100%',
              }}
              onChange={handleChange}
              options={realmoption}
            />
            <Input maxLength={250}  onChange={marketinputonChange} value={marketLink} placeholder="For sale market link"/>
            <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '120px' }}>
            <ImgCrop rotationSlider modalTitle="Edit image" cropShape="rect" aspect={curraspect} quality="9">
              <Upload
              action={geourl+"addimg"}
              listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
              >
                {fileList.length < 1 && 'Upload image'}
              </Upload>
            </ImgCrop>
            </div>  
            <div style={{ flex: 1 }}>
              <p>Default Display:</p>
              <Radio.Group defaultValue="1" onChange={onRadioChange} value={defaultRadio} size="small">
                <Radio value={1}>Land Id</Radio>
                <Radio value={4}>Image</Radio>
                <Radio value={3}>Realm Name</Radio>
                <Radio value={2}>Description</Radio>
              </Radio.Group>
            </div>
          </div>
          </Flex>
          
          </div>
        </div>
        
      </Modal></ConfigProvider>
    </>
  );
});
export default Setupland;
function isNullOrEmpty(str) {
  if (str === null || str === undefined ) {
      return true;
  }
  return false;
}
