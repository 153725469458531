import React, { Component } from 'react';
import ReactMapGL, { Marker } from 'mapbox-gl';

const CustomMarker=(props)=> {
  const { latitude, longitude, imageUrl } = props;

  const markerStyle = {
  position: 'absolute',
  width: '50px',
  height: '50px',
  left: '-25px',
  top: '-25px',
  border: '2px solid #fff',
  borderRadius: '50%',
  backgroundColor: '#007cbf',
  textAlign: 'center',
  color: '#fff',
  fontSize: '16px',
  fontWeight: 'bold',
  padding: '15px',
  cursor: 'pointer',
  boxShadow: '0 0 0 2px rgba(0,0,0,0.5)',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover'
  };

  const triangleStyle = {
  position: 'absolute',
  bottom: '-10px',
  left: '50%',
  marginLeft: '-10px',
  width: '0',
  height: '0',
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderTop: '10px solid #007cbf'
  };
  
  return (
  <Marker latitude={latitude} longitude={longitude}>
  <div style={markerStyle}>
  <div style={triangleStyle} />
  </div>
  </Marker>
  );  

}

export default CustomMarker;
