import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Card, Flex} from 'antd';
import Icon from '@ant-design/icons'

function DisConnect() {
  const dispatch = useDispatch();
  const disSvg=() => (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="disconnect" width="2em" height="2em" fill="#333333" aria-hidden="true"><path d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 00-11.3 0L209.4 249a8.03 8.03 0 000 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"></path></svg>
  );
  const DisIcon = (props) => <Icon component={disSvg} {...props} />;
  const cardStyle = {
    width: '100%',
    marginTop:'19px',
    marginBottom:'5px'
  };
  const imgStyle = {
    display: 'block',
    alignSelf:'center',
    marginLeft:26,
  };
  
  useEffect(() => {
   
  }, []);
  return (
    <div>
        <Card
          hoverable
          style={cardStyle}
          styles={{
            body: {
              padding: 0,
              overflow: 'hidden',
            },
          }}
          onClick={() => {
            dispatch({
              type: 'SET_DCONNECTED',
              payload: false,
            });
          }}
        >
          <Flex>
            <DisIcon style={imgStyle}/>
            <Flex
              vertical
              style={{
                padding: 26,
                fontSize:16,
                fontWeight:400
              }}
            >
                Disconnect 
            </Flex>
          </Flex>
        </Card>
        
      </div>  
  );
}

export default DisConnect;
