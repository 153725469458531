import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Map from './Map';

function App() {
  return (
    <div>
    <Router>
      <Routes>
      <Route path="/" element={<Map />} />
      </Routes>
      </Router>
    </div>
  );
}

export default App;
