import React from 'react';
import '../assets/Rectangles.css';
const SetupRectangles = (props) => {
    const { rectangles } = props;
    const colors = [
        {
            border: '#113658',
            background: '#63bcd1',
            city: ''
        },
        {
            border: '#f7d8c6',
            background: '#ef7a34',
            city: 'Quark City'
        },
        {
            border: '#cee3e6',
            background: '#227638',
            city: 'Neutron City'
        },
        {
            border: '#cee3e6',
            background: '#4a8188',
            city: 'Electron City'
        },
        {
            border: '#113658',
            background: '#63bcd1',
            city: ''
        },
        {
            border: '#eff3ff',
            background: '#1f84e0',
            city: 'Photon City'
        },
        {
            border: '#d7c9e3',
            background: '#956db6',
            city: 'Infinity City'
        },
        {
            border: '#113658',
            background: '#63bcd1',
            city: ''
        },
        {
            border: '#113658',
            background: '#63bcd1',
            city: 'Island'
        },
        {
            border: '#feb24c',
            background: '#144bb2',
            city: 'Sophon Continent'
        }
      ];

        const getBackColor=(lid)=>{
            const colorIndex = Number(lid.toString()[0]) || 0;
            const borderColor = colors[colorIndex].border;
            const backgroundColor = colors[colorIndex].background;
            return {bordColor:borderColor,backColor:backgroundColor}
        }

        const minX = Math.min(...rectangles.flatMap(item => item[1][0].map(coord => coord[0])));
        const minY = Math.min(...rectangles.flatMap(item => item[1][0].map(coord => coord[1])));

const topLeftRectangle = rectangles.reduce((topLeft, current) => {
    const currentMinX = Math.min(...current[1][0].map(coord => coord[0]));
    const currentMinY = Math.min(...current[1][0].map(coord => coord[1]));
    if (currentMinX < topLeft.minX || currentMinY < topLeft.minY) {
    return { lid: current[0], minX: currentMinX, minY: currentMinY };
    }
    return topLeft;
    }, { lid: null, minX: Infinity, minY: Infinity });

        const createStylesFromGeoJSON = (coordinates,lid) => {
            const bounds = coordinates[0];
            const actualWidth = Math.max(...bounds.map(coord => coord[0])) - Math.min(...bounds.map(coord => coord[0]));
            const actualHeight = Math.max(...bounds.map(coord => coord[1])) - Math.min(...bounds.map(coord => coord[1]));
            const left = Math.min(...bounds.map(coord => coord[0])) - minX;
            const top = Math.min(...bounds.map(coord => coord[1])) - minY;

            let offsetX = left / actualWidth * 60;
            let offsetY = top / actualHeight * 60;
            if (lid === topLeftRectangle.lid) {
                offsetX = 0;
                offsetY = 0;
            }
            
            const getcolor=getBackColor(lid)
            return {
            position: 'absolute',
            left: `${offsetX}px`,
            top: `${offsetY}px`,
            width: '60px',
            height: '60px',
            border: `1px solid ${getcolor.bordColor}`,
            backgroundColor: getcolor.backColor,
            backgroundSize: `${actualWidth}px ${actualHeight}px`
            }
        }
        
    return (
        <div style={{position:'relative',height:'180px'}}>
            {rectangles.map((item, index) => {

                return(<div
                key={item[0]}
                style={createStylesFromGeoJSON(item[1],item[0])}
                className='geojson-rectangle'

                >
                        <span style={{ color: 'white' ,fontSize:'12px'}}>
                        #{item[0]}
                        </span>
                </div>)
            })}
        </div>
    );
        
    
}
    export default SetupRectangles;
