import React, { useState, useEffect } from 'react';
import '../assets/Rectangles.css';
const Rectangles = (props) => {
    const { rectangles, onSelectClick } = props;
    const [checkedState, setCheckedState] = useState({});
    useEffect(() => {
        setCheckedState({});
    }, [rectangles]);
    const handleClick = (key) => {
        let newState = {};
        let curr = 0;
        for (let k in checkedState) {
            if (k === key) {
                curr++
            }
            newState[k] = (k === key) ? true : false;
        }
        if (curr === 0) {
            newState[key] = true;
        }
        setCheckedState(newState);
        onSelectClick(key);
    };
    const handleMouseEnter = (e, backgroundColor) => {
        // const style = e.target.style.backgroundColor;
        // style.filter = 'brightness(70%)';
        let darkerColor = darkenRGB(backgroundColor, 0.2); // 降低20%的亮度
        e.target.style.backgroundColor = darkerColor;
        let spans = e.target.getElementsByTagName('span');
        for (let span of spans) {
            span.style.backgroundColor = 'transparent';
        }
    };
    const handleMouseLeave = (e, backgroundColor) => {
        e.target.style.backgroundColor = backgroundColor;
        let spans = e.target.getElementsByTagName('span');
        for (let span of spans) {
            span.style.backgroundColor = 'transparent';
        }
    };
    const colors = [
        {
            border: '#113658',
            background: '#63bcd1'
        },
        {
            border: '#f7d8c6',
            background: '#eab589'
        },
        {
            border: '#cee3e6',
            background: '#9ed764'
        },
        {
            border: '#cee3e6',
            background: '#77b6bd'
        },
        {
            border: '#113658',
            background: '#63bcd1'
        },
        {
            border: '#eff3ff',
            background: '#bdd2ff'
        },
        {
            border: '#d7c9e3',
            background: '#baa4d2'
        },
        {
            border: '#113658',
            background: '#63bcd1'
        },
        {
            border: '#113658',
            background: '#63bcd1'
        },
        {
            border: '#feb24c',
            background: '#3182bd'
        }
    ];

        const getBackColor=(lid)=>{
            const colorIndex = Number(lid.toString()[0]) || 0;
            const borderColor = colors[colorIndex].border;
            const backgroundColor = colors[colorIndex].background;
            return {bordColor:borderColor,backColor:backgroundColor}
        }
        const minX = Math.min(...rectangles.flatMap(item => item[1][0].map(coord => coord[0])));
        const minY = Math.min(...rectangles.flatMap(item => item[1][0].map(coord => coord[1])));
        const createStylesFromGeoJSON = (coordinates,lid) => {
            const bounds = coordinates[0];
            const actualWidth = Math.max(...bounds.map(coord => coord[0])) - Math.min(...bounds.map(coord => coord[0]));
            const actualHeight = Math.max(...bounds.map(coord => coord[1])) - Math.min(...bounds.map(coord => coord[1]));
            const left = Math.min(...bounds.map(coord => coord[0])) - minX;
            const top = Math.min(...bounds.map(coord => coord[1])) - minY;

            const offsetX = left / actualWidth * 60;
            const offsetY = top / actualHeight * 60;
            const getcolor=getBackColor(lid)
            return {
            position: 'absolute',
            left: `${offsetX}px`,
            top: `${offsetY}px`,
            width: '60px',
            height: '60px',
            border: `1px solid ${getcolor.bordColor}`,
            backgroundColor: getcolor.backColor,
            backgroundSize: `${actualWidth}px ${actualHeight}px`
            }
        }
        
    return (
        <div style={{position:'relative',height:'180px'}}>
            {rectangles.map((item, index) => {
                const backgcolor=getBackColor(item[0]).backColor
                return(<div
                key={item[0]}
                style={createStylesFromGeoJSON(item[1],item[0])}
                className='geojson-rectangle'
                onClick={() => handleClick(item[0])}
                onMouseEnter={(e) => handleMouseEnter(e, backgcolor)}
                onMouseLeave={(e) => handleMouseLeave(e, backgcolor)}
                >
                        <span style={{ color: 'white' ,fontSize:'12px'}}>
                        #{item[0]}
                        </span>
                        {checkedState[item[0]] && (
                            <span
                            style={{
                            color: 'white',
                            fontFamily: 'FontAwesome',
                            position: 'absolute',
                            right: '5px',
                            bottom: '5px',
                            }}
                            >✔</span>
                            )}
                </div>)
            })}
        </div>
    );
        
    
}
    export default Rectangles;

    function darkenRGB(color, percent) {
        if (!color) {
            return color;
        }

        if (color.startsWith('#')) {
            color = hexToRgb(color);
        }
        let [r, g, b] = color.match(/\d+/g).map(Number);

        // 计算暗色
        r = Math.floor(r * (1 - percent));
        g = Math.floor(g * (1 - percent));
        b = Math.floor(b * (1 - percent));

        return `rgb(${r}, ${g}, ${b})`;
    }
    function hexToRgb(hex) {
        let r = parseInt(hex.slice(1, 3), 16);
        let g = parseInt(hex.slice(3, 5), 16);
        let b = parseInt(hex.slice(5, 7), 16);

        return `rgb(${r}, ${g}, ${b})`;
    }
