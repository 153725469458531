import React, { useState,useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from "react-redux";
import { Button, Modal,Alert, message } from 'antd';
import Rectangles from './Rectangles';
import axios from 'axios';
import { jsonurl,containeratomid } from '../networkConfig.js';
import '../assets/Dmint.css'
const Dmint = forwardRef((props, ref) => {
  const dwallet = useSelector(state => state.dwallet);
  const dselectedfeatures = useSelector(state => state.dselectedfeatures);
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [lid, setLid] = useState('');
  const [showSellandAlert, setshowSellandAlert] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
      setLid('')
    }, [dselectedfeatures]);
  useImperativeHandle(ref, () => ({
    showModal,
    }));
  const showModal = () => {
    setOpen(true);
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Json err',
    });
  };
  const walletDmint =async (djson) => {
    if(dwallet==='wizz'){
      window.wizz.requestMint({
        type: 'mint_dmitem',
            atomicalId: containeratomid,
            dmitem: djson,
            satsIn: 1000,
      }).catch(error => {
        console.error('Error minting :', error);
      });
    }

  };
  const handleOk = () => {
    if(isNullOrEmptyOrNotNumber(lid.toString())){
      setshowSellandAlert(true);
    }else{
      setshowSellandAlert(false);
      getItemJson(lid)
      .then(data => {
          if (data !== 'error') {
            walletDmint(data)
          } else {
            error();
          }
      })
      .catch(error => {
        error();
      });
     
    }
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setOpen(false);
    // }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSelectClick = (lid) => {
    setLid(lid);
    if(isNullOrEmptyOrNotNumber(lid.toString())){
      setshowSellandAlert(true);
    }else{
      setshowSellandAlert(false);
    }
  }
  return (
    <>
      
      <Modal
        open={open}
        title="DMint..."
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="dmint" className="custom-button"  onClick={handleOk}>
            DMint
          </Button>,
        ]}
      >
        {contextHolder}
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '210px' }}>
          <Rectangles rectangles={dselectedfeatures} onSelectClick={handleSelectClick} />
          </div>
          <div style={{ flex: 1 }}>
            <h2>Notice:</h2>
            <p>In addition to the fees required for minting, you must pay <strong>0.00021 BTC</strong> per land.</p>
            <p>Payment must be made between <strong>4</strong> and <strong>15</strong> block confirmations, or the mint is invalid.</p>
            <p>Please follow the wizz wallet prompts.</p>
          </div>
        </div>
          {showSellandAlert && <Alert
          message="Please select a plot of land to Mint"
          type="warning"
          style={{marginTop:'5px'}}
          />}
 
          <Alert
            description={<span>Please read the <a href="https://dvl-1.gitbook.io/atomicals-land/dmint-tutorial" target="_blank" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>DMint Tutorial</a> carefully, or you may risk losing your BTC.</span>}
            type="error"
            />
      </Modal>
    </>
  );
});
export default Dmint;
function isNullOrEmptyOrNotNumber(str) {
  if (str === null || str === undefined) {
      return true;
  }
  str = str.trim();
  if (str === '' || isNaN(str)) {
      return true;
  }
  return false;
}
async function getItemJson(lid){
  let returnStr='error'
  try {
    const response = await axios.get(jsonurl+lid+".json");
    returnStr = response.data;
  } catch (error) {
    returnStr='error'
  }
  return returnStr;
}