import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import { Card, Flex,Typography} from 'antd';
import { ReactComponent as Wizzicon } from '../assets/wizz.svg';
import { containeratomid } from '../networkConfig.js';
const { Text, Link } = Typography;
function WizzWallet() {
  const dispatch = useDispatch();
  const cardStyle = {
    width: '100%',
    marginBottom:'5px'
  };
  const imgStyle = {
    display: 'block',
    alignSelf:'center',
    marginLeft:26,
    width: 36,
    height:36,
  };
  const [wizzInstalled, setWizzInstalled] = useState(false);
  const [displayInstalled, setDisplayInstalled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [publicKey, setPublicKey] = useState("");
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });
  const [network, setNetwork] = useState("livenet");

  const getBasicInfo = async () => {
    const wizz = window.wizz;
    const [address] = await wizz.getAccounts();
    setAddress(address);
    dispatch({
      type: 'SET_DADDRESS',
      payload: address,
    });
    dispatch({
      type: 'SET_DWALLET',
      payload: 'wizz',
    });

    const publicKey = await wizz.getPublicKey();
    setPublicKey(publicKey);

    const balance = await wizz.getAssets();
    // console.log(balance);
    //{$dmitem:"10001",$parent_container:"21000a85165d4c3035cb06758ebab26bdbfabab776264480a9d76f38f9d48f56i0",confirmed:true}
    if(balance){
      // console.log(balance.atomicalNFTs )
      const filteredData = [];
      const realmnameData = [];
      for (const item of balance.atomicalNFTs) {
          if (item["$parent_container"] === containeratomid && item["confirmed"] && item["$dmitem"]) {
              filteredData.push({
                  "$dmitem": item["$dmitem"],
              });
          }
          if (item["subtype"] === "realm" && item["confirmed"] && item["$realm"]) {
            realmnameData.push({
                "$realm": item["$realm"],
            });
          }
      }
      dispatch({
        type: 'SET_MYREALMS',
        payload: realmnameData,
      });
      dispatch({
        type: 'SET_MYLANDS',
        payload: filteredData,
      });
    }
    const network = await wizz.getNetwork();
    if(network){
      setNetwork(network);
    }
    
  };

  const selfRef = useRef({ accounts: [] });
  const self = selfRef.current;
  const handleAccountsChanged = (_accounts) => {
    if (self.accounts[0] === _accounts[0]) {
      // prevent from triggering twice
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);

        dispatch({
          type: 'SET_DCONNECTED',
          payload: true,
        });
   
      setAddress(_accounts[0]);
      getBasicInfo();
    } else {
      setConnected(false);
      dispatch({
        type: 'SET_DCONNECTED',
        payload: false,
      });
    }
  };

  const handleNetworkChanged = (network) => {
    setNetwork(network);
    getBasicInfo();
  };
  useEffect(() => {
    async function checkUnisat() {
      let wizz = window.wizz;

      for (let i = 1; i < 10 && !wizz; i += 1) {
          await new Promise((resolve) => setTimeout(resolve, 100*i));
          wizz =window.wizz;
      }

      if(wizz){
          setWizzInstalled(true);
      }else if (!wizz)
          return;
      wizz.on("accountsChanged", handleAccountsChanged);
      wizz.on("networkChanged", handleNetworkChanged);

      return () => {
        wizz.removeListener("accountsChanged", handleAccountsChanged);
        wizz.removeListener("networkChanged", handleNetworkChanged);
      };
    }

    checkUnisat().then();
  }, []);

  const wizz = window.wizz;
  return (
    <div>
        <Card
          hoverable
          style={cardStyle}
          styles={{
            body: {
              padding: 0,
              overflow: 'hidden',
            },
          }}
          onClick={async () => {
            if(wizzInstalled){
              try{
                const result = await wizz.requestAccounts();
                handleAccountsChanged(result);
              }catch(e){
                console.log("wizzerror",e)
              }
            }else{
              setDisplayInstalled(true)
            }
          }}
        >
          <Flex>
            <Wizzicon style={imgStyle}/>
            <Flex
              vertical
              style={{
                padding: 26,
                fontSize:16,
                fontWeight:400
              }}
              
            >
                Wizz Wallet 
            </Flex>
          </Flex>
        </Card>
        {displayInstalled &&  <Text>Wallet is not installed, <Link style={{ color: "#000000",fontWeight:600,textDecoration:"underline" }} href="https://wizzwallet.io" target="_blank">click here</Link> to download and refresh the page.</Text>}
      </div>  
  );
}

export default WizzWallet;
