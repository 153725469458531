import React, {  useEffect } from 'react';
import { Card, Flex} from 'antd';
import { ReactComponent as Okicon } from '../assets/okx_wallet_icon.svg';

function OkxWallet() {

  const cardStyle = {
    width: '100%',
    marginTop:'19px',
    marginBottom:'5px',
    backgroundColor: '#d9d9d9',
    color: '#595959'
  };
  const imgStyle = {
    display: 'block',
    alignSelf:'center',
    marginLeft:26,
    width: 36,
    height:36,
    filter: 'grayscale(100%)'
  };
  
  useEffect(() => {
   
  }, []);
  return (
    <div>
        <Card
          // hoverable
          style={cardStyle}
          styles={{
            body: {
              padding: 0,
              overflow: 'hidden',
            },
          }}
        >
          <Flex>
            <Okicon style={imgStyle}/>
            <Flex
              vertical
              style={{
                padding: 26,
                fontSize:16,
                fontWeight:400
              }}
            >
                Okx Wallet 
            </Flex>
          </Flex>
        </Card>
        
      </div>  
  );
}

export default OkxWallet;
