import { CopyOutlined } from '@ant-design/icons'
import React, { useState, useEffect, useRef } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Drawer,Button,Avatar,Space} from 'antd';
import { ReactComponent as Flyto } from '../assets/flyto.svg';
import { ReactComponent as Alogo } from '../assets/alogo.svg';
import WizzWallet from './WizzWallet';
import UnisatWallet from './UnisatWallet';
import OkxWallet from './OkxWallet';
import DisConnect from './DisConnect';
import MyLand from './MyLand';
import { docsurl } from '../networkConfig.js';
import '../assets/TopBar.css';
function shorten(nstar,nend,str) {
  if (str.length <= nstar-nend) {
    return str;
  }
    return str.slice(0, nstar) + "..." + str.slice(nend);
  }
  

function TopBar({ flyTo }) {
  const inputRef = useRef();
  const dconnected = useSelector(state => state.dconnected);
  const daddress = useSelector(state => state.daddress);
  const mylands = useSelector(state => state.mylands);
  // const mylandsgeo = useSelector(state => state.mylandsgeo);
  // const mylandsgeo =[{$dmitem:'10001'},{$dmitem:'20002'},{$dmitem:'30003'},{$dmitem:'50005'},{$dmitem:'60006'},{$dmitem:'80003'},{$dmitem:'90007'}]
  const mintopenwallet = useSelector(state => state.mintopenwallet);
  const dispatch = useDispatch();
  // console.log("mygeo:",mylandsgeo)
  const [open, setOpen] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [placement, setPlacement] = useState('left');
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const colors = [
    {
        border: '#113658',
        background: '#63bcd1',
        city: ''
    },
    {
        border: '#f7d8c6',
        background: '#ef7a34',
        city: 'Quark City'
    },
    {
        border: '#cee3e6',
        background: '#227638',
        city: 'Neutron City'
    },
    {
        border: '#cee3e6',
        background: '#4a8188',
        city: 'Electron City'
    },
    {
        border: '#113658',
        background: '#63bcd1',
        city: ''
    },
    {
        border: '#eff3ff',
        background: '#1f84e0',
        city: 'Photon City'
    },
    {
        border: '#d7c9e3',
        background: '#956db6',
        city: 'Infinity City'
    },
    {
        border: '#113658',
        background: '#63bcd1',
        city: ''
    },
    {
        border: '#113658',
        background: '#63bcd1',
        city: 'Island'
    },
    {
        border: '#feb24c',
        background: '#144bb2',
        city: 'Sophon Continent'
    }
  ];
  const [searchValue, setSearchValue] = useState('');
  const [options] = useState([{cname:'Quark city',clid:10966},{cname:'Neutron city',clid:21271},{cname:'Electron city',clid:32185},{cname:'Photon city',clid:51385},{cname:'Infinity city',clid:62043},{cname:'Sophon continent',clid:92710}]);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    setSearchValue(option.cname);
    setShowOptions(false);
    flyTo(option.cname)
  };
  const handleFocus = (event) => {
    event.target.select();
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
    
  const handleSubmit = () => {
    flyTo(inputRef.current.value)
};
  const copyClick = (adrtext) => {
    navigator.clipboard
    .writeText(adrtext)
    .then(() => {
      alert("Text copied to clipboard!");
    })
    .catch((error) => {
      alert("Failed to copy text: " + error);
    });
  }
  const goDocs=()=>{
    window.open(docsurl)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (mintopenwallet) {
      setOpen(true);
      dispatch({
        type: 'SET_MINTOPENWALLET',
        payload: false,
      });
    }
  }, [mintopenwallet]);
  
  return (
    <div>
    <div className="top-bar" style={{ display: 'flex',position: 'fixed', left: '10px',top:'10px', zIndex: 999 }}>
      <button onClick={goDocs} className="boxshadw" style={{height:'28px',width:'28px',background:'#fff',borderRadius:'4px', marginRight: '10px',marginBottom:'10px'}}>
                <Alogo/>
            </button>
      <button onClick={showDrawer} className="boxshadw" style={{height:'28px',width:'108px',fontSize:'14px', textAlign:'center', background: '#fff', borderRadius: '4px', marginRight: '10px',marginBottom:'10px' }}>{dconnected ? (shorten(6,-4,daddress)):("Connect Wallet")}</button>
      <Drawer
        title={dconnected ? (shorten(8,-6,daddress)):("Connect your wallet")}
        placement={placement}
        closable={true}
        onClose={onClose}
        open={open}
        key={placement}
        extra={
          dconnected &&
          <Space>
            <Button type="" onClick={() => copyClick(daddress)} icon={<CopyOutlined/>} size="small"></Button>
          </Space>
        }
      >
        {dconnected ? (
          <div>
          <MyLand showChildrenDrawer={showChildrenDrawer}/>
          <DisConnect/></div>
        ):(
          <div><WizzWallet/>
        <UnisatWallet/>
        <OkxWallet/></div>
        )}
 
        <Drawer
          title="My Lands"
          placement={placement}
          closable={false}
          onClose={onChildrenDrawerClose}
          open={childrenDrawer}
        >
          <div>
          {/* <MyRectangles myrectangles={mylandsgeo} /> */}
          {mylands && mylands.map((land, index) => {
            const colorIndex = Number(land.$dmitem.toString()[0]) || 0;
            const borderColor = colors[colorIndex].border;
            const backgroundColor = colors[colorIndex].background;
            const cityname = colors[colorIndex].city;
            return (
            <div key={index} style={{ marginBottom: '10px' }}>
              <Avatar
                  style={{
                    borderColor:borderColor,
                    backgroundColor: backgroundColor,
                    verticalAlign: 'middle',
                    borderRadius:'1px'
                  }}
                  shape="square"
                  size="large"
                >
                  #{land.$dmitem}
                </Avatar>
                <span style={{
                    margin: '0 16px',
                    verticalAlign: 'middle',
                  }}>{cityname}</span>
                <Button
                className="custom-button"
                  size="small"
                  style={{
                    margin: '0 16px',
                    verticalAlign: 'middle',
                  }}
                  onClick={() => flyTo(land.$dmitem)}  
                >
                  Flyto
                </Button>
            </div>
            );
            })}
          </div>
        </Drawer>
      </Drawer>
      <div className="input-button-wrapper" style={{ display: 'flex' }}>
            <div style={{ position: 'relative' }}>
                <div className="boxshadw" style={{width:'180px',height:'28px', background: '#fff',border:'0px', borderRadius: '4px', marginRight: '10px' }}>
                    <span style={{marginLeft:'5px'}}>+</span>
                    <input 
                    style={{width:'156px', height:'16px',background: '#fff',marginLeft:'1px',marginRight:'5px',border:'0px', fontSize:'14px' }}
                    type="text" 
                    ref={inputRef}
                    placeholder="realm name / land id"
                    value={searchValue} 
                    onFocus={handleFocus}
                    onChange={(e) => {setSearchValue(e.target.value);setShowOptions(false);}} onClick={() => setShowOptions(true)} 
                    onKeyUp={handleKeyPress}
                    />
                </div>
            {showOptions && (
                <div ref={dropdownRef} style={{ position: 'absolute', top:'30px',borderRadius:'4px',fontSize:'14px',padding:'5px',cursor:'pointer', backgroundColor: '#fff', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 1 }}>
                    {options.map((option, index) => (
                    <div className="option" key={index} onClick={() => handleSelect(option)}>
                        {option.cname}
                    </div>
                    ))}
                </div>
                )}
                </div>
            <button onClick={handleSubmit} className="boxshadw" style={{height:'28px',width:'28px',background:'#fff',borderRadius:'4px'}} title="Fly To">
                <Flyto/>
            </button>
        </div>
    </div>

      <div className="boxshadw bottom-right-bar" >
        <div className="square first-square"></div>
        <div className="text">Owner</div>
        <div className="square second-square"></div>
        <div className="text">Sold</div>
      </div>
    </div>
  );
}

export default TopBar;
